import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {API} from "../../config/api.config";
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";

// Define a type for the slice state
interface IState {
    businessRole: any;
    businessRoleDetail: any;
    businessRoles: any;
    success: any;
    loading: boolean;
    error: any;
    message: any;
}

// Initial state
const initialState: IState = {
    businessRole: null,
    businessRoleDetail: null,
    businessRoles: null,
    success: null,
    loading: false,
    error: null,
    message: null
};

// Async thunks

export const getAllBusinessRoles = createAsyncThunk(
    'business-roles/all',
    async (_, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await API.get('/business-role/get-all');
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to store';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const businessRoleSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        clearBusinessRoleState: (state: IState) => {
            state = initialState
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getAllBusinessRoles.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getAllBusinessRoles.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.businessRoles = action.payload.data;
                state.success = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getAllBusinessRoles.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            })

    },
});

export const {clearBusinessRoleState} = businessRoleSlice.actions;
export default businessRoleSlice.reducer;
