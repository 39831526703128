import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {API} from "../../config/api.config";
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";

// Define a type for the slice state
interface IState {
    storeType: any;
    storeTypeDetails: any;
    storeTypes: any;
    success: any;
    loading: boolean;
    error: any;
    message: any;
}

// Initial state
const initialState: IState = {
    storeType: null,
    storeTypeDetails: null,
    storeTypes: null,
    success: null,
    loading: false,
    error: null,
    message: null
};

// Async thunks

export const getAllStoreTypes = createAsyncThunk(
    'store-types/all',
    async (_, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await API.get('/store-type/get-all');
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const storeTypeSlice = createSlice({
    name: 'store-types',
    initialState,
    reducers: {
        clearStoreTypeState: (state: IState) => {
            state = initialState
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getAllStoreTypes.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getAllStoreTypes.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.storeTypes = action.payload.data;
                state.success = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getAllStoreTypes.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

export const {clearStoreTypeState} = storeTypeSlice.actions;
export default storeTypeSlice.reducer;
