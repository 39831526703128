import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import authReducer from './slices/auth.slice';
import storeReducer from './slices/store.slice';
import businessRoleReducer from './slices/business-role.slice';
import dashboardTypeReducer from './slices/dashboard-type.slice';
import storeTypeReducer from './slices/store-type.slice';
import productReducer from './slices/product.slice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['businessRole', 'dashboardType', 'storeType', 'product']
};

const rootReducer = combineReducers({
    auth: authReducer,
    store: storeReducer,
    businessRole: businessRoleReducer,
    dashboardType: dashboardTypeReducer,
    storeType: storeTypeReducer,
    product: productReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
            },
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export type IRootState = ReturnType<typeof rootReducer>;
