import React, {useEffect} from 'react'
import './App.css';
import {Outlet, useRoutes} from "react-router-dom";
import {routes} from "./routes/app.route";
import {IRootState} from "./store";
import {useDispatch, useSelector} from "react-redux";
import {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import {clearAuthState, verifyToken} from "./store/slices/auth.slice";
import {setAuthToken} from "./config/api.config";
import {clearStoreState, getStoreDetails} from "./store/slices/store.slice";
import {getCookie} from "./utils/utils";

function App() {
    const {user, token} = useSelector((state: IRootState) => state.auth);
    const dispatch = useDispatch<ThunkDispatch<IRootState, any, AnyAction>>();
    const pages = useRoutes(routes);
    const cookieToken = getCookie('token') || '';

    useEffect(() => {
        dispatch(clearStoreState())
        if (cookieToken !== 'undefined') {
            setAuthToken(cookieToken, 'auth')
            dispatch(verifyToken())
        } else {
            dispatch(clearAuthState())
        }
    }, []);

    useEffect(() => {
        if (user && user.roles.includes('seller')) {
            setAuthToken(token, 'go-seller')
            dispatch(getStoreDetails(user._id))
        }
    }, [user]);

    return (
        <>
            <div>{pages}</div>
        </>
    );
}

export default App;
