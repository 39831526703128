
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {API} from "../../config/api.config";
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";

// Define a type for the slice state
interface IState {
    store: any;
    storeDetail: any;
    stores: any;
    success: any;
    loading: boolean;
    error: any;
    message: any;
}

// Initial state
const initialState: IState = {
    store: null,
    storeDetail: null,
    stores: null,
    success: null,
    loading: false,
    error: null,
    message: null
};

// Async thunks

export const createStore = createAsyncThunk(
    'store/create',
    async (userData: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await API.post('/store/create-store', userData);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to store';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getStoreDetails = createAsyncThunk(
    'store/details',
    async (userId: any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await API.get('/store/get-detail/'+userId);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        clearStoreState: (state:IState) => {
            state.store = null;
            state.storeDetail = null;
            state.stores = null;
            state.loading = false;
            state.success = null;
            state.error = null;
            state.message = null;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(createStore.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(createStore.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.store = action.payload.data;
                state.success = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(createStore.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getStoreDetails.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getStoreDetails.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.storeDetail = action.payload.data;
                state.success = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getStoreDetails.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.success = null;
                state.error = action.error.message;
            })

    },
});

export const { clearStoreState } = storeSlice.actions;
export default storeSlice.reducer;
