import axios from 'axios';
import {AUTH_API_URL, API_URL} from "../constants";

const API = axios.create({
    baseURL: API_URL
});

const authAPI = axios.create({
    baseURL: AUTH_API_URL
});

const setAuthToken = (token: string, apiType = 'go-seller') => {
    if (token) {
        apiType === 'go-seller'
            ? API.defaults.headers.common['Authorization'] = `Bearer ${token}`
            : authAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete API.defaults.headers.common['Authorization'];
    }
};

const setContentType = (type: string, apiType = 'go-seller') => {
    if (apiType === 'go-seller') {
        API.defaults.headers.common['Content-Type'] = type || 'application/json';
    } else {
        authAPI.defaults.headers.common['Content-Type'] = type || 'application/json';
    }
}

export {API, authAPI, setAuthToken, setContentType};
