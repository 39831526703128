
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {API} from "../../config/api.config";
import {AsyncThunkConfig, GetThunkAPI} from "@reduxjs/toolkit/dist/createAsyncThunk";

// Define a type for the slice state
interface IState {
    product: any;
    productDetail: any;
    products: any;
    success: any;
    loading: boolean;
    error: any;
    message: any;
}

// Initial state
const initialState: IState = {
    product: null,
    productDetail: null,
    products: null,
    success: null,
    loading: false,
    error: null,
    message: null
};

// Async thunks
export const getAllProducts = createAsyncThunk(
    'products/get-all',
    async (_, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await API.get('/product/get-all/');
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getProductDetails = createAsyncThunk(
    'products/detail',
    async (slug:any, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
        try {
            const response = await API.get('/product/detail/'+slug);
            return response.data;
        } catch (error: any) {
            const message =
                error.response?.data?.message || error.message || 'Failed to fetch';
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Slice
export const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        clearProductState: (state:IState) => {
            state.product = null;
            state.productDetail = null;
            state.products = null;
            state.loading = false;
            state.success = null;
            state.error = null;
            state.message = null;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getAllProducts.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getAllProducts.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.products = action.payload.data;
                state.success = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getAllProducts.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
                state.message = action.payload.message
                state.success = action.payload.status;
            })
            .addCase(getProductDetails.pending, (state: IState) => {
                state.loading = true;
            })
            .addCase(getProductDetails.fulfilled, (state: IState, action: any) => {
                state.loading = false;
                state.productDetail = action.payload.data;
                state.success = action.payload.status;
                state.message = action.payload.message;
            })
            .addCase(getProductDetails.rejected, (state: IState, action: any) => {
                state.loading = false;
                state.error = action.error.message;
                state.message = action.payload.message
                state.success = action.payload.status;
            })

    },
});

export const { clearProductState } = productSlice.actions;
export default productSlice.reducer;
